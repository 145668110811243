import TopCategories from '~/themes/base/components/category/TopCategories'

export default {
  mixins: [TopCategories],
  data() {
    return {
      gridColumnClass: 'col-lg-auto mb-2 mb-sm-3 mb-md-4 mt-lg-2',
    }
  },
}
