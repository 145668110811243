import _ from 'lodash'

const SLIDE_TIME = 4000 // Milliseconds
const TIMER_REFRESH_RATE = 60 // Per second

export default {
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      start: false,
      previousBanner: this.banners[1],
      activeBanner: this.banners[0],
      timer: 0,
      interval: null,
    }
  },
  mounted() {
    this.timerStart()
  },
  methods: {
    throttledChangeBanner: _.throttle(function(banner) {
      this.changeBanner(banner)
    }, 200),
    changeBanner(banner) {
      if (banner !== this.activeBanner) {
        this.start = false
        this.previousBanner = this.activeBanner
        this.activeBanner = banner
        this.timer = 0
      }
    },
    next() {
      const currentIndex = _.findIndex(this.banners, this.activeBanner)
      const nextIndex = (currentIndex + 1) % this.banners.length
      this.changeBanner(this.banners[nextIndex])
    },
    prev() {
      const currentIndex = _.findIndex(this.banners, this.activeBanner)
      const previousIndex = (currentIndex + this.banners.length - 1) % this.banners.length
      this.changeBanner(this.banners[previousIndex])
    },
    swipeHandler(direction) {
      if (direction === 'left') {
        this.next()
      }
      if (direction === 'right') {
        this.prev()
      }
    },
    timerStop() {
      clearInterval(this.interval)
      this.interval = null
    },
    timerStart() {
      this.timerStop()
      if (this.banners.length > 1) {
        const tickTime = 1000 / TIMER_REFRESH_RATE
        const timerJump = 100 / (SLIDE_TIME / tickTime)
        this.interval = setInterval(() => {
          this.timer += timerJump
          if (this.timer > 100) {
            this.next()
            this.timerStart()
          }
        }, tickTime)
      }
    },
  },
}
