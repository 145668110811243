<template>
  <PageHomepage
    :banners-square-large="bannersSquareLarge"
    :block-homepage-content="blockHomepageContent"
    :featured-articles="featuredArticles"
    :featured-banners="featuredBannersSorted"
    :featured-products="{mostSoldProducts, newestProducts}"
    :interesting-articles="interestingArticlesSorted"
    :text-slides="validTextSlides"/>
</template>

<script>
import Component from '~/sites/shop/pages'

export default {
  extends: Component,
  middleware: [
    'trailingSlashRemove',
    ({ store }) => {
      store.commit('locale/SET_ALTERNATIVE_PATHS', {
        cs: '/',
        sk: '/',
      })
    },
  ],
  nuxtI18n: {
    paths: {
      cs: '/',
    },
  },
  computed: {
    featuredBannersSorted() {
      return this.featuredBanners && this.featuredBanners.length > 0 ? this.sortByOrder(this.featuredBanners) : []
    },
    interestingArticlesSorted() {
      return this.interestingArticles && this.interestingArticles.length > 0
        ? this.sortByOrder(this.interestingArticles)
        : []
    },
    validTextSlides() {
      return this.textSlides.filter(slide => slide && slide.box_color && slide.box_color.length > 0)
    },
  },
  methods: {
    sortByOrder(data) {
      if (Array.isArray(data)) {
        data.sort((a, b) => {
          return a.order - b.order
        })
      }
      return data
    },
  },
  async asyncData({ store, res, app }) {
    if (process.server) {
      res.set('X-Inspishop-Cache-Control', `public, max-age=${app.$env.STATELESS_TTL}`)
    }

    store.commit('locale/SET_CANONICALS', {
      nuxtI18nPaths: Component.nuxtI18n.paths,
    })

    const data = {
      blockHomepageContent: app.$themeSettings.components.PageHomepage.showHomepageTextBlock
        ? store.dispatch('homepage/LOAD_HOMEPAGE_CONFIGURATION')
        : null,
      featuredBanners: app.$themeSettings.components.PageHomepage.showSlider
        ? store.dispatch('banner/LOAD_HOMEPAGE_BANNERS')
        : null,
      mostSoldProducts: app.$themeSettings.components.PageHomepage.mostSoldProducts.enable
        ? store.dispatch('product/LOAD_FEATURED_PRODUCTS', { type: 'most_sold', size: 8 })
        : null,
      newestProducts: app.$themeSettings.components.PageHomepage.newestProducts.enable
        ? store.dispatch('product/LOAD_FEATURED_PRODUCTS', { type: 'newest', size: 8 })
        : null,
      articles: app.$themeSettings.components.PageHomepage.featuredArticles.enable
        ? store.dispatch('article/LOAD_ARTICLES', {
            page: 0,
            perPage: app.$themeSettings.components.PageHomepage.featuredArticles.count,
          })
        : [],
      bannersSquareLarge: store.dispatch('banner/LOAD_HOMEPAGE_BOXES'),
      interestingArticles: store.dispatch('articleInteresting/LOAD_INTERESTING_ARTICLES'),
      textSlides: store.dispatch('banner/LOAD_INFO_BOXES'),
    }
    const resolvedData = await Promise.allValues(data, [store.dispatch('category/FETCH_TOP_CATEGORIES')])
    await app.apolloProvider.defaultClient.cache.reset()

    return resolvedData
  },
}
</script>
