import { hydrateWhenVisible } from 'vue-lazy-hydration'

import CriticalContentMixin from '~/mixins/CriticalContentMixin'
import LayoutBase from '@theme/components/shop/LayoutBase'
import SeoMixin from '~/mixins/SeoMixin'
import SliderText from '@theme/components/content/SliderText'
import TopCategories from '@theme/components/category/TopCategories'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'

const AppImage = () => import('@theme/components/atom/AppImage')
const AppTitle = () => import('@theme/components/atom/AppTitle')
const BannerSquareLarge = () => import('@theme/components/content/BannerSquareLarge')
const Carousel = () => import('@theme/components/utils/Carousel')
const ProductBox = () => import('@theme/components/product/ProductBox')
const Slider = () => import('@theme/components/content/Slider')

export default {
  components: {
    AppImage: hydrateWhenVisible(AppImage),
    AppTitle: hydrateWhenVisible(AppTitle),
    BannerSquareLarge: hydrateWhenVisible(BannerSquareLarge),
    Carousel: hydrateWhenVisible(Carousel),
    LayoutBase,
    ProductBox: hydrateWhenVisible(ProductBox),
    Slider: hydrateWhenVisible(Slider),
    SliderText: hydrateWhenVisible(SliderText),
    TopCategories,
  },
  props: {
    featuredBanners: Array,
    bannersSquareLarge: Array,
    interestingArticles: Array,
    featuredProducts: Object,
    textSlides: Array,
  },
  head() {
    return this.getSeoHead()
  },
  mixins: [CriticalContentMixin, SeoMixin, UtilityGridMixin],
  computed: {
    criticalContentBreakpoints() {
      return [
        { viewportMinWidth: 991, imageWidth: 1920, imageHeight: 450 },
        { viewportMinWidth: 320, imageWidth: 500, imageHeight: 300 },
      ]
    },
    criticalContentPaths() {
      const elements = []

      if (this.featuredBanners.length > 0) {
        const banner = this.featuredBanners[0]
        elements.push(
          banner.main_image_translatable && banner.main_image_translatable.length > 0
            ? banner.main_image_translatable[0].path
            : banner.main_image[0].path,
        )
      }

      return elements
    },
  },
  methods: {
    getLCPElements() {
      return this.getCriticalContentMetaPaths()
    },
  },
}
